<template>
	<!--active切换为手动赋值模式 是为了避免刷新页面时默认为active赋值会触发副作用函数-->
	<div>
		<van-tabs :active="active" title-active-color="#FF445E" line-width=".18rem" swipe-threshold="4"
		          @click-tab="handleClickTab">
			<!--官方分组-->
			<van-tab v-for="(item, index) in officialGroup" :title="item.title" :key="index + item.officalId"
			         :name="`officialGroup,${item.officalId}`" :badge="item.officalId === followIngId ? '跟发中' : ''">
				<div class="official-info">
					<div class="push-info">
						{{ item.desc ? item.desc : '暂未设置推送介绍' }}
					</div>
					<div class="push-plain mt-1">
						<div class="b fs-17">今日推品计划（{{ todayPushPlan.length }}）</div>
						<ul v-if="todayPushPlan.length">
							<li v-for="i in todayPushPlan" class="push-plain__item" :key="i.officalId">
								<div class="push-plain__line mr-4"></div>
								<info-cell :avatar="i.pushImg" avatar-height=".44rem" :round="false" context-follow-direction="column"
								           contextAlignSelf="normal" :customer-style="{paddingRight : 0}">
									<template #context>
										<span class="color-gray fs-12">{{ i.pushDateTime }}</span>
										<p class="mt-1 fs-14 text-flow push-plain__title">{{ i.pushTitile }}</p>
									</template>
									<template #right>
										<div class="flex">
											<van-switch :modelValue="i.isPush"
											            size=".12rem"
											            @change="handleSetPushState($event, item, i)"/>
											<p v-bury="'wangpaihaohuoyulan'" class="ml-3 flex" @click="previewMaterial(i)">
												<span class="fs-12  color-gray mr-1">预览</span>
												<van-icon name="arrow" size="12px"/>
											</p>
										</div>
									</template>
								</info-cell>
							</li>
						</ul>
						<no-data v-else :showButton="false" height="2.1rem"
						         title="今日暂无推品计划哦！"/>
					</div>
				</div>
			</van-tab>
			<!--官方推品-->
			<van-tab v-for="(item, index) in officialMaterial" :title="item.groupName"
			         :key="index + officialGroup.length +item.chatRoomId"
			         :name="`officialMaterial,${item.chatRoomId}`" :badge="item.chatRoomId === followIngId ? '跟发中' : ''">
				<div class="official-info">
					<div class="push-info">
						{{ item.desc ? item.desc : '暂未设置推送介绍' }}
					</div>
					<div class="b fs-17">商品预览</div>
					<div class="goods-preview mt-3" v-if="goodsPreviewData.length">
						<lazy-component>
							<chat v-for="(i, index) in goodsPreviewData" :item="i" :key="index"/>
						</lazy-component>
					</div>
					<no-data v-else :showButton="false" height="2.1rem"
					         title="今日暂无推品计划哦！"/>
				</div>
			</van-tab>
		</van-tabs>

		<chat-list :id="gId" v-model:show="officialMaterialPreview"/>
		<footer class="footer flex column">
			<button class="button" @click="handleFollow">确认跟发</button>
			<span class="mt-2 fs-12 color-gray">一个播货群只能绑定一个素材群组</span>
		</footer>
	</div>

</template>

<script>
export default {
	name: 'TheOfficialMaterial'
}
</script>
<script setup>
import { onMounted, ref, watchPostEffect } from 'vue'
import {
	ApiOfficalPushGroupList,
	ApiQueryOfficalChatRoomMsgs,
	ApiQueryOfficialChatrooms, ApiSetFollowLiveChatroomToOfficalType,
	ApiSetFollowLiveChatroomState
} from '../../api/FollowLive'
import { ApiGetUserTodayPushGroupPage, ApiUserTodayPushGroupSaveNew } from '../../api/PushProduct'
import InfoCell from '../../components/InfoCell'
import chatList from '@/components/chatList/index.vue'
import Chat from '../../components/chatList/chat'
import { Toast } from 'vant'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import img from '@/assets/miniapp-icon.png'

const store = useStore()
const router = useRouter()
const active = ref()
const followGroupInfo = store.getters.hostingGroupInfo
// 跟发中Id
const followIngId = ref()
// 官方素材分组
const officialGroup = ref([])
// 官方素材
const officialMaterial = ref([])
// 今日推品计划
const todayPushPlan = ref([])
// 商品预览数据
const goodsPreviewData = ref([])
// 官方素材预览
const officialMaterialPreview = ref(false)
// 官方素材预览
const gId = ref()

watchPostEffect(() => {
	// 保证能获取到官方素材的信息
	if (active.value && (officialGroup.value.length > 0 || officialMaterial.value.length > 0)) {
		const activeData = active.value.split(',')
		const params = {
			type: activeData[0],
			id: activeData[1]
		}
		if (params.type === 'officialGroup') {
			getUserTodayPushGroup(params.id)
		} else if (params.type === 'officialMaterial') {
			getOfficialMaterialInfo(params.id)
		}
	}
})
onMounted(async () => {
	// 分组素材
	await getOfficialGroup()
	// 官方素材
	await getOfficialMaterial()
	// type 群类型1.个人采集群，2.官方素材群，3.官方精选
	// 当跟发群的跟发类型为 2 时 获取正在跟发中的id
	if (followGroupInfo.type === 2) {
		// 获取跟发中的id
		getFollowId()
	} else {
		if (officialGroup.value.length) {
			active.value = `officialGroup,${officialGroup.value[0].officalId}`
		} else if (officialMaterial.value.length) {
			active.value = `officialMaterial,${officialMaterial.value[0].chatRoomId}`
		}
	}

})
const handleClickTab = (val, title) => {
	active.value = val.name
}

// 获取跟发中的id
const getFollowId = () => {
	// officalChatroomSerialNo 为 '0'  就是官方分组
	if (followGroupInfo.officalId > 0) {
		followIngId.value = followGroupInfo.officalId
		// 设置默认值
		const activeId = followIngId.value === 0 ? officialGroup.value[0].officalId : followIngId.value
		active.value = `officialGroup,${activeId}`
	} else {
		// 官方素材
		followIngId.value = followGroupInfo.officalChatroomSerialNo
		// 如果官方素材有值则激活值为 officialMaterial  否者就设置为 官方分组的第一个
		if(followIngId.value){
			active.value = `officialMaterial,${followIngId.value}`
		}else {
			active.value = `officialGroup,${officialGroup.value[0].officalId}`
		}
	}
}
// 获取官方分组群
const getOfficialGroup = async () => {
	try {
		const res = await ApiOfficalPushGroupList()
		officialGroup.value = res ? res : []
	} catch (e) {
		console.log(e)
	}
}
// 获取官方素材群
const getOfficialMaterial = async () => {
	try {
		const res = await ApiQueryOfficialChatrooms()
		officialMaterial.value = res ? res.map(i => {
			i.groupName = i.showName ?
					i.showName :
					(i.chatRoomName ? i.chatRoomName : '未命名')
			return i
		}) : []
	} catch (e) {
		console.log(e)
	}
}

// 获取今日推品数据
const getUserTodayPushGroup = async (id) => {
	const toast = Toast.loading({
		duration: 0,
		forbidClick: true,
		message: '加载中...'
	})
	try {
		const res = await ApiGetUserTodayPushGroupPage({
			pageIndex: 1,
			pageSize: 1000,
			sortName: '',
			sortOrder: 0,
			officalId: id,
			roomId: followGroupInfo.roomId
		})
		todayPushPlan.value = res && res.dataList ? res.dataList : []
		toast.clear()
	} catch (e) {
		console.warn(e)
		toast.clear()

	}
}

// 获取官方素材预览信息
const getOfficialMaterialInfo = async (id) => {
	const toast = Toast.loading({
		duration: 0,
		forbidClick: true,
		message: '加载中...'
	})
	try {
		const robotWxId = officialMaterial.value.find(i => i.chatRoomId === id).robotWxId
		const res = await ApiQueryOfficalChatRoomMsgs({
			robotWxId: robotWxId,
			chatroomId: id
		})
		// 商品预览数据
		goodsPreviewData.value = res ? res.map(i => {
			if (i.msgType === 2013) {
				i.logo = img
				i.href = i.msgDes
				i.msgDes = ''
			}
			return i
		}) : []
		toast.clear()
	} catch (e) {
		console.warn(e)
		toast.clear()

	}
}
/**
 * 设置跟发状态
 * @param val 状态
 * @param par 分组信息
 * @param child 推品信息
 * @returns {Promise<void>}
 */
const handleSetPushState = async (val, par, child) => {
	try {
		await ApiUserTodayPushGroupSaveNew({
			officalId: par.officalId,
			gIds: [child.gId],
			isPush: val,
			roomId: followGroupInfo.roomId
		})
		Toast('切换成功！')
		child.isPush = val
	} catch (e) {
		console.log(e)
	}
}
// 预览
const previewMaterial = (val) => {
	gId.value = val.gId
	officialMaterialPreview.value = true
}
// 确认跟发
const handleFollow = async () => {
	try {
		const activeData = active.value.split(',')
		let data = {
			roomId: followGroupInfo.roomId,
			officalId: activeData[0] === 'officialGroup' ? Number(activeData[1]) : 0,
			officialChatroomSerialNo: activeData[0] !== 'officialGroup' ? activeData[1] : ''
		}
		await ApiSetFollowLiveChatroomToOfficalType({
			...data
		})
		Toast.success('设置成功！')
		await router.replace('/index')
	} catch (e) {
		console.warn(e)
	}
}
</script>
<style lang="scss" scoped>
:deep(.van-badge--top-right) {
	width: 50px;
	top: -4px;
}

.official-info {
	background: white;
	padding: 0 15px;
}

.push-info {
	padding: 15px 0;
	white-space: pre-line;
	color: #999999;
	border-radius: px(4);
}

// 推品计划
.push-plain__item {
	padding-left: 20px;
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 1px;
		left: 0;
		top: 0;
		bottom: 0;
		background: #F6F6F6;
	}

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		top: 50%;
		transform: translate3d(-42%, -50%, 0);
		left: 0;
		background: #FF445E;
		z-index: 1;
	}

	&:first-of-type {
		&:after {
			margin-top: 15px;
		}
	}

	.push-plain__title {
		width: 160px;
	}
}


// 商品预览
.goods-preview {
	padding-top: 10px;
	padding-bottom: 30px;
	background: #F8F8F8;

}

@import './tabCommon';

</style>
