<template>
	<div class="collect-material">
		<div class="title flex-1 fs-12 color-gray mb-2">注意事项：</div>
		<ul class="notice ">
			<li class="fs-12 color-gray mt-1" v-for="i in noticeList">{{ i }}</li>
		</ul>

		<div class="flex mt-4 pl-3 pr-3">
			<div class="fs-17 b">采集对象</div>
			<span v-bury="'caijiduixiangxiugai'" class="change flex-right fs-12"
			      @click="toGroup">
				{{ isSetCollectObj ? '更改' : '立即设置' }}</span>
		</div>
		<div class="select-object mt-2" @click="handleChange">
			<div class="select-object__group" :class="{'no-pb' : hostingGroupInfo.routeType === 0}">
				<template v-if="hostingGroupInfo.teacherChatroomId">
					<div class="group-sign flex v-normal">
						<AvatarImg :avatar="hostingGroupInfo.teacherChatroomHead" avatarHeight=".48rem" :round="false"/>
						<div class="div ml-2 flex">
							<div class="group-name  fs-14 b text-flow">
								{{ hostingGroupInfo.teacherChatroomName }}
							</div>
						</div>

					</div>
					<!--        <div class="group-personnel mt-4 flex  wrap h-between">-->
					<div class="group-personnel mt-4  h-between" v-if="hostingGroupInfo.routeType === 0">
						<template v-for="i in hostingGroupInfo.teacherInfos">
							<div class="flex column group-personnel__item">
								<avatar-img :avatar="i.headImgUrl"/>
								<p class="b mt-1 fs-12 text-ellipsis text-center group-personnel__item--name">
									{{ i.nickName }}
								</p>
							</div>
						</template>
					</div>
				</template>

				<div class="no-object" v-else>
					<div class="flex column mt-3">
						<div class="relative">
							<AvatarImg avatarHeight=".48rem"/>
						</div>
						<div class="fs-12 color-gray mt-1">暂未设置</div>
					</div>
				</div>
			</div>
		</div>
		<footer class="footer flex column mt-3">
			<button class="button" @click="handleFollow">确认跟发</button>
			<span class="mt-2 fs-12 color-gray">一个播货群只能绑定一个素材群组</span>
		</footer>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { ApiGetFollowTimeRange, ApiGetTeacher, ApiSetFollowTimeRange } from '@/api/FollowLive'
import { ApiSetFollowLiveChatroomToUserType } from '../../api/FollowLive'
import { Notify, Toast } from 'vant'

const defaultAvatar = require('../../assets/avatar.png')

export default {
	name: 'Index',
	components: {
		AvatarImg: defineAsyncComponent(() => import('@/components/AvatarImg/index'))
	},
	data() {
		return {
			noticeList: Object.freeze([
				'链接加价以被采集的素材群链接为准，需设置与采集对象一样的加价',
				'为保证稳定性和消息的及时性，采集对象推送的文件大小超过10M将无法转播',
				'暂不支持采集群内企业微信用户的信息',
				'因微信风控，每日发送信息条数太多会被限制，可控制推品时段'
			]),
			// 是否指定人选
			isAppoint: false,
			collectionObject: [],
			defaultAvatar: defaultAvatar,

			id: 0
		}
	},
	computed: {
		...mapGetters(['hostingGroupInfo', 'robotInfo']),
		// 已经设置采集对象
		isSetCollectObj() {
			return  this.hostingGroupInfo.teacherChatroomId
		}
	},
	created() {
	},
	methods: {

		// 确认采集
		async handleFollow() {
			if (!this.isSetCollectObj) {
				Notify({
					type: 'warning',
					message: '请先设置采集对象哦！'
				})
				return
			}
			try {
				await ApiSetFollowLiveChatroomToUserType({
					// 群Id
					roomId: this.hostingGroupInfo.roomId,
				})
				Toast.success('设置成功！')
				await this.$router.replace('/index')
			} catch (e) {
				console.log(e)
			}
		},
		handleChange() {
			// 没有群信息就拦截
			if (!this.isSetCollectObj) return
			this.$router.replace({
				path: '/selectMemberGather',
				query: {
					chatroomId: this.hostingGroupInfo.teacherChatroomId,
					selectType: this.hostingGroupInfo.routeType,
					chatroomImgUrl: this.hostingGroupInfo.teacherChatroomHead,
					chatroomName: this.hostingGroupInfo.teacherChatroomName
				}
			})
		},
		toGroup() {
			this.$router.push('/selectGroupGather')
		}
	}
}
</script>
<style scoped lang="scss">
.collect-material {
	background-color: white;
	padding: 15px 0;

	.title, .notice {
		padding: 0 15px;
	}

	.notice {
		padding-left: 30px;
		li {
			//list-style: auto;
			list-style-type: auto;
		}
	}

	.change {
		color: #34557B;
	}
}

.select-object {
	margin-left: 15px;
	margin-right: 15px;

	.select-object__group {
		padding: px(20) 0;
		// border-radius: px(4);
		box-shadow: 0 px(2) px(6) 0 rgba(0, 0, 0, 0.06);
		background: #FFFFFF;
		border-radius: px(12);

		.group-sign {
			padding: 0 px(15);

			.group-img {
				width: px(48);
				height: px(48);
				border-radius: px(4);
			}

			.group-name {
				color: #433C3C;
				line-height: px(20);
			}
		}

		.group-personnel {
			background: #F9F9F9;
			border-radius: px(4);
			padding: px(18) px(20) 0;
			display: grid;
			justify-content: space-between;
			grid-gap: px(8);
			grid-template-columns: repeat(auto-fill, px(60));

			.group-personnel__item {
				margin-bottom: px(20);

				.group-personnel__item--img {
					width: px(40);
					height: px(40);
				}

				.group-personnel__item--name {
					width: px(60);
				}
			}
		}
	}

	.no-pb {
		padding-bottom: 0;
	}
}

@import './tabCommon';

</style>
