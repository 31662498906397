<template>
	<div class="page flex column v-normal">
		<back-home/>
		<info-cell :avatar="groupInfo.chatroomImgUrl" avatar-height=".44rem" :round="false"
		           :customer-style="{padding: '20px 15px'}">
			<template #context>
				<p class="fs-14 ml-1 chat-room--name text-flow">{{ groupInfo.chatroomName }} </p>
				<img v-bury="'qunzhuxiao'" src="../../assets/icon_delete.png"
				     class="icon_delete ml-2"
				     @click="deleteGroupVisible = true">
			</template>
			<template #right>
				<van-switch :modelValue="groupInfo.state"
				            :active-value="1"
				            :inactive-value="-1"
				            size=".21rem"
				            @change="handleSetPushState($event)"/>
			</template>
		</info-cell>
		<TimePicker :startTime="timeRange[0]" :endTime="timeRange[1]"
		            class='mt-2' @confirm="timePickerConfirm"/>
		<div class="material-tabs flex-1">
			<div class="divider mb-1"></div>
			<div class="material-tabs__wrap">
				<div class="material-tabs__nav">
					<div v-bury="'guanfangsucai'" class="material__tab official__material"
					     :class="{active: tabActive === 'OfficialMaterial'}"
					     @click="tabActive = 'OfficialMaterial'">
						官方素材
					</div>
					<div v-bury="'ciajisucai'" class="material__tab collection__material"
					     :class="{active: tabActive === 'collection'}"
					     @click="tabActive = 'collection'">
						采集素材 <span v-if="groupInfo.type === 1" class="is-follow">跟发中</span>
					</div>
				</div>
			</div>
			<div class="material-tabs__content">
				<keep-alive>
					<component :is="currentComponent"/>
				</keep-alive>
			</div>
		</div>
		<logoutDialog v-model="deleteGroupVisible" key="showDeleteGroup" @cancel="deleteGroupVisible = false"
		              @confirm="handleDeleteGroup">
			<p class="text-center">群注销后，机器人将不会进行素材跟发，是否确认注销？</p>
		</logoutDialog>
	</div>
</template>

<script>
export default {
	name: 'followMaterial'
}
</script>
<script setup>
import InfoCell from '../../components/InfoCell'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import {
	ApiGetFollowTimeRange, ApiQueryFollowLiveChatrooms,
	ApiRemoveFollowLiveChatroom,
	ApiSetFollowLiveChatroomState,
	ApiSetFollowTimeRange
} from '../../api/FollowLive'
import { Notify, Toast } from 'vant'
import LogoutDialog from '../../components/LogoutDialog'
import TimePicker from '../../components/TimePicker'
// 官方素材
import OfficialMaterial from './TheOfficialMaterial'
// 采集素材
import CollectMaterial from './TheCollectMaterial'
import { useRoute, useRouter } from 'vue-router'
import AplusRecord from '../../utils/AplusRecord'

const router = useRouter()
const route = useRoute()
const store = useStore()
const groupInfo = computed(() => {
	return store.getters.hostingGroupInfo
})
// 当前组件
const currentComponent = computed(() => {
	return tabActive.value === 'OfficialMaterial' ? OfficialMaterial : CollectMaterial
})

const deleteGroupVisible = ref(false)
// 推品时间段
const timeRange = ref([])
const tabActive = ref('OfficialMaterial')
onMounted(async () => {
	// 判断是否设置采集对象
	// await getHostingGroupInfo()
	if (route.query.from === 'selectMemberGather' || groupInfo.value.type === 1) {
		tabActive.value = 'collection'
	}
	await getTimeRange()
})

// 设置跟发状态
const handleSetPushState = async (val) => {
	try {
		await ApiSetFollowLiveChatroomState({
			roomId: groupInfo.value.roomId,
			// 1跟发中 2停止跟发
			state: val
		})
		Toast('切换成功！')
		// 先手动修改状态
		store.commit('setHostingGroupInfo', {
			state: val
		})
		AplusRecord('zidongbohuokaiguan', val)
	} catch (e) {
		console.log(e)
	}
}
// 获取托管群信息
const getHostingGroupInfo = async () => {
	try {
		const roomId = route.query.roomId ? [route.query.roomId] : []
		const res = await ApiQueryFollowLiveChatrooms(roomId)
		let hostingGroupInfo = res[0]
		// 跟发（官方/采集）素材名
		hostingGroupInfo.followMaterialName = ''
		// 播货群名
		hostingGroupInfo.chatroomName = hostingGroupInfo.chatroomName ? hostingGroupInfo.chatroomName : '未命名'
		// 有id 说明已设置
		if (hostingGroupInfo.teacherChatroomId) {
			hostingGroupInfo.followMaterialName = hostingGroupInfo.teacherChatroomName ? hostingGroupInfo.teacherChatroomName : '未命名'
		} else {
			hostingGroupInfo.followMaterialName = '暂未设置'
		}
		// 重置托管群信息
		store.commit('resetHostingGroupInfo')
		// 设置当前托管群信息
		store.commit('setHostingGroupInfo', hostingGroupInfo)
	} catch (e) {
		console.warn(e, 'getHostingGroupInfo')
	}
}
// 删除群
const handleDeleteGroup = async () => {
	const toast = Toast.loading({
		message: '注销中...',
		forbidClick: true,
		loadingType: 'spinner',
		duration: 0
	})
	try {
		await ApiRemoveFollowLiveChatroom({
			robotWxId: groupInfo.value.robotWxSerialNo,
			chatroomId: groupInfo.value.chatroomSerialNo
		})
		deleteGroupVisible.value = false
		toast.clear()
		Notify({
			type: 'success',
			message: '注销成功'
		})
		await router.replace('/index')
	} catch (e) {
		toast.clear()
		console.log(e)
	}
}
const getTimeRange = async () => {
	try {
		const res = await ApiGetFollowTimeRange()
		timeRange.value = [res.startTimeRange, res.endTimeRange]
	} catch (e) {
		console.log('ApiGetFollowTimeRange', e)
	}
}
// 推品时间段
const timePickerConfirm = async (startTime, endTime) => {
	timeRange.value[0] = startTime
	timeRange.value[1] = endTime
	const toast = Toast.loading({
		message: '提交中...',
		forbidClick: true,
		duration: 0
	})
	try {
		await ApiSetFollowTimeRange({
			startTimeRange: startTime,
			endTimeRange: endTime
		})
		toast.clear()
		Notify({
			type: 'success',
			message: '修改成功'
		})
	} catch (e) {
		toast.clear()
	}
}
</script>

<style lang="scss" scoped>
.page {
	overflow-y: auto;
}

.icon_delete {
	width: 18px;
	height: 18px;
}

.chat-room--name {
	height: 20px;
	line-height: 20px;
	max-width: 200px;
}

.divider {
	height: 1px;
	width: 100%;
	background-color: rgba(244, 244, 244, 1)

}

.material-tabs {
	height: 100px;
	background-color: white;

	.material-tabs__wrap {
		height: 46px;

		.material-tabs__nav {
			height: 100%;
			padding: 0 5px;
			position: relative;
			display: flex;
			user-select: none;
			padding-bottom: 0.15rem;
		}

		.material__tab {
			background: #F6F6F6;
			position: relative;
			display: flex;
			flex: 1;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			cursor: pointer;
			font-size: 17px;
			font-weight: bold;
			color: #999999;

			.is-follow {
				position: absolute;
				right: 30px;
				top: 2px;
				min-width: 16px;
				padding: 0 0.03rem;
				color: #fff;
				font-weight: 500;
				font-size: 0.12rem;
				font-family: -apple-system-font, Helvetica Neue, Arial, sans-serif;
				line-height: 1.2;
				text-align: center;
				background: #ee0a24;
				border: 0.01rem solid #fff;
				border-radius: 9.99rem;
			}
		}

		.material__tab.active {
			color: #433C3C;
			background: white;
			transition: background 0.3s;

			&:after {
				transition: background 0.3s;

			}
		}

		.official__material.active {
			&:after {
				content: "";
				position: absolute;
				right: 0;
				height: 0;
				width: 0;
				border-top: 23px solid #F6F6F6;
				border-right: 10px solid #F6F6F6;
				border-bottom: 23px solid transparent;
				border-left: 10px solid transparent;
			}
		}

		.collection__material.active {
			&:after {
				content: "";
				position: absolute;
				left: 0;
				height: 0;
				width: 0;
				border-top: 23px solid #F6F6F6;
				border-left: 10px solid #F6F6F6;
				border-right: 10px solid transparent;
				border-bottom: 23px solid transparent;
			}
		}
	}

	.material-tabs__content {
		padding-bottom: 90px;
	}
}


</style>
